<template>
  <section>
    <p class="text-h6 secondary--text">Administración de catálogo OBS</p>
    <v-row class="filters-row">
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-autocomplete
          v-model="searchParam"
          :items="listado_obs_select_new"
          item-text="codigoNombre"
          :filter="filterObsSelect"
          item-value="id"
          label="Código, Obra, Bien o Servicio"
          @keyup="isLoading = true"
          :loading="isLoading"
          outlined
          clearable
          @change="setFilter()"
          :maxLength="200"
          @click:clear="setObsFilter(null), getListaobs()"
          :search-input.sync="searchValidator"
          @update:search-input="searchObsSelect"
          hide-no-data
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <div class="d-flex justify-end">
          <v-btn class="ma-2" color="secondary" dark @click="Agregarobs()">
            Agregar
          </v-btn>
          <!-- <v-btn
            class="ma-2"
            outlined
            color="btnGreen"
            @click="cargarOBS()"
            v-if="false"
          >
            <v-icon>mdi-microsoft-excel</v-icon>
            Cargar
          </v-btn> -->
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <data-table-component
          :headers="headers"
          :items="listado_obs"
          v-models:select="perPageModel"
          v-models:pagina="pageModel"
          :total_registros="filters.total_rows"
          @paginar="paginar"
          @sortFunction="sortByColumn"
        >
          <template v-slot:[`item.activo`]="{ item }">
            <v-chip :color="getColor(item.activo)" dark>
              {{ item.activo ? "Activo" : "Inactivo" }}
            </v-chip>
          </template>
          <template v-slot:[`item.id_obs_clasificacion`]="{ item }">
            {{ getClasificacion(item.id_obs_clasificacion) }}
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  large
                  color="primary"
                  @click="verProveedor(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>

              <span>Editar OBS</span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-col>
    </v-row>

    <!-- Modal Agregar un nuevo obs -->
    <v-dialog v-model="dialog" width="900" @click:outside="clearNuevoOBS()">
      <v-card>
        <v-card-title class="text-h6 primary--text">
          {{ isEditable ? "Editar un OBS" : "Agregar un nuevo OBS" }}
        </v-card-title>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="8" sm="6" md="4" lg="10">
              <v-text-field
                outlined
                label="Nombre o Detalle *"
                placeholder="Digite el nombre del OBS"
                v-model="nuevoObs.nombre"
                :error-messages="nombreErrors"
                @input="$v.nuevoObs.nombre.$touch()"
                :maxLength="200"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" sm="6" md="4" lg="4">
              <v-select
                :items="listado_obstype"
                v-model="nuevoObs.id_tipo_obs"
                outlined
                label="Nivel *"
                item-text="nombre"
                placeholder="Seleccione"
                item-value="id"
                :error-messages="tipoObsErrors"
                @blur="$v.nuevoObs.id_tipo_obs.$touch()"
                @change="getObsPadreList()"
              >
              </v-select>
            </v-col>
            <v-col cols="8" sm="6" md="4" lg="4">
              <v-checkbox
                :disabled="!isEditable"
                v-model="nuevoObs.activo"
                label="Activo"
                color="secondary"
                :error-messages="activoErrors"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="nuevoObs.id_tipo_obs != 1"
              cols="8"
              sm="6"
              md="4"
              lg="10"
            >
              <v-autocomplete
                :items="listado_obs_padre"
                outlined
                :disabled="!nuevoObs.id_tipo_obs"
                label="Padre *"
                item-text="nombre"
                item-value="id"
                placeholder="Busque o seleccione"
                v-model="selectedParent"
                return-object
                @change="setParentOBS()"
                :error-messages="obsPadreErrors"
                @blur="$v.nuevoObs.id_obs_padre.$touch()"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="8" sm="6" md="4" lg="4">
              <v-text-field
                :error-messages="codigoErrorMessage"
                @input="$v.nuevoObs.codigo.$touch()"
                v-model="nuevoObs.codigo"
                outlined
                label="Código *"
                :maxLength="getMaxLength"
              >
              </v-text-field>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <v-select
                :items="listado_categorias_obs"
                item-text="nombre"
                item-value="id"
                outlined
                :disabled="disableClassification || isEditable"
                placeholder="Seleccione"
                label="Clasificación *"
                v-model="nuevoObs.id_obs_clasificacion"
                :error-messages="classificationErrorMessage"
                @blur="$v.nuevoObs.id_obs_clasificacion.$touch()"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-col cols="8" sm="3" md="4" lg="4">
            <v-btn
              class="ma-2"
              outlined
              @click="(dialog = false), clearNuevoOBS()"
            >
              Cerrar
            </v-btn>

            <v-btn
              class="ma-2"
              color="secondary"
              dark
              @click="GuardaNuevoObs()"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Modal cargar excel obs -->
    <v-dialog v-model="dialogCargar" width="800">
      <v-card>
        <v-card-title class="text-h6 primary--text">
          Carga de catálogo
        </v-card-title>
        <br />
        <v-card-text>
          <v-alert
            color="success"
            border="left"
            elevation="2"
            colored-border
            icon="mdi-information-outline"
          >
            <v-row>
              <v-col cols="11" class="secondary--text text-center">
                Si desea cargar un catálogo de OBS deberá descargar y utilizar
                el siguiente formato csv.
              </v-col>
              <v-col>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      @click="downloadFormat"
                      color="secondary"
                      >mdi-file-download-outline</v-icon
                    >
                  </template>
                  <span>Descargar csv</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-alert>
          <v-file-input
            v-model="documento"
            outlined
            label="Archivo *"
            accept=".csv"
            :error-messages="documentoErrorMessage"
          />
        </v-card-text>
        <v-card-actions>
          <v-col cols="12">
            <v-btn class="ma-2" outlined @click="dialogCargar = false">
              Cerrar
            </v-btn>

            <v-btn class="ma-2" color="secondary" dark @click="saveDocument()">
              Cargar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import AppPaginationComponent from "../../components/AppPaginationComponent.vue";
import DataTableComponent from "../../components/DataTableComponent.vue";
import {
  required,
  helpers,
  requiredIf,
  numeric,
} from "vuelidate/lib/validators";

const alpha = helpers.regex(
  "alpha",
  /^[a-zA-ZÀ-ÿ0-9-_,.\(\)\[\]\#\%\/\u00f1\u00d1 ]*$/
);
export default {
  components: { AppPaginationComponent, DataTableComponent },
  name: "administracionObsView",
  validations: {
    nuevoObs: {
      activo: { required },
      id_tipo_obs: { required },
      id_obs_padre: {
        required: requiredIf(function () {
          return this.nuevoObs.id_tipo_obs != 1 ? true : false;
        }),
      },
      nombre: { required, alpha },
      id_obs_clasificacion: { required },
      codigo: { required, numeric },
    },
    documento: { required },
  },
  data: () => ({
    listado_obstype: [],
    dialog: false,
    dialogCargar: false,
    dialogEditar: false,
    checkbox: true,
    searchParam: "",
    codigo: null,
    nuevoObs: {
      activo: true,
      codigo: null,
      id_tipo_obs: null,
      id_obs_padre: null,
      nombre: null,
      tipo_obs: null,
      id_obs_clasificacion: "",
    },
    documento: null,
    menuDataRange: false,
    searchValidator: null,
    headers: [
      {
        text: "Código",
        align: "right",
        sortable: true,
        value: "codigo",
        width: "5%",
      },
      {
        text: "Nombre o Detalle",
        align: "left",
        sortable: true,
        value: "nombre",
        width: "30%",
      },
      {
        text: "Tipo",
        align: "left",
        sortable: true,
        value: "tipo_obs",
        width: "5%",
      },
      {
        text: "Clasificación",
        align: "left",
        sortable: true,
        value: "obs_clasificacion",
        width: "5%",
      },
      {
        text: "Estado",
        align: "center",
        sortable: false,
        value: "activo",
        width: "5%",
      },

      {
        text: "Código padre",
        align: "right",
        sortable: true,
        value: "codigo_pariente",
        width: "5%",
      },
      // {
      //   text: "Acciones",
      //   align: "center",
      //   sortable: false,
      //   value: "acciones",
      // },
    ],
    searchValidator: null,
    listado_obs_padre: [],
    isEditable: false,
    selectedParent: {},
    disableClassification: false,
    obs_timer: null,
    isLoading: false,
    listado_obs_select_new: [],
  }),
  computed: {
    ...mapState("administracionObs", [
      "listado_obs",
      "listado_categorias_obs",
      "filters",
      "listado_obs_select",
    ]),
    getMaxLength() {
      switch (this.nuevoObs.id_tipo_obs) {
        case 1:
          return 2;
        case 2:
          return 4;
        case 3:
          return 6;
        default:
          return 8;
      }
    },
    // Models para data table
    pageModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setPage(value);
      },
    },
    perPageModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setPerPage(value);
      },
    },
    detailedObsList() {
      return this.listado_obs_select.map((col) => ({
        id: col.id,
        nombre: `${col.codigo} - ${col.nombre}`,
      }));
    },
    nombreErrors() {
      const errors = [];
      if (!this.$v.nuevoObs.nombre.$dirty) return errors;
      !this.$v.nuevoObs.nombre.required && errors.push("Campo requerido");
      !this.$v.nuevoObs.nombre.alpha &&
        errors.push("Campo nombre solo acepta caracteres alfanuméricos");
      return errors;
    },
    tipoObsErrors() {
      const errors = [];
      if (!this.$v.nuevoObs.id_tipo_obs.$dirty) return errors;
      !this.$v.nuevoObs.id_tipo_obs.required && errors.push("Campo requerido");
      return errors;
    },
    obsPadreErrors() {
      const errors = [];
      if (!this.$v.nuevoObs.id_obs_padre.$dirty) return errors;
      !this.$v.nuevoObs.id_obs_padre.required && errors.push("Campo requerido");
      return errors;
    },
    activoErrors() {
      const errors = [];
      if (!this.$v.nuevoObs.activo.$dirty) return errors;
      !this.$v.nuevoObs.activo.required && errors.push("Campo requerido");
      return errors;
    },
    documentoErrorMessage() {
      const errors = [];
      if (!this.$v.documento.$dirty) return errors;
      !this.$v.documento.required && errors.push("Debe cargar un documento");
      return errors;
    },
    classificationErrorMessage() {
      const errors = [];
      if (!this.$v.nuevoObs.id_obs_clasificacion.$dirty) return errors;
      !this.$v.nuevoObs.id_obs_clasificacion.required &&
        errors.push("Campo requerido");
      return errors;
    },
    codigoErrorMessage() {
      const errors = [];
      if (!this.$v.nuevoObs.codigo.$dirty) return errors;
      !this.$v.nuevoObs.codigo.required && errors.push("Campo requerido");
      !this.$v.nuevoObs.codigo.numeric &&
        errors.push("El campo código solo admite números");
      return errors;
    },
  },
  methods: {
    ...mapActions("administracionObs", [
      "getListaobs",
      "getListadoCategoriasObs",
      "PostAgregarObs",
      "putActualizarObs",
      "getListaObsSelect",
    ]),
    ...mapMutations("administracionObs", [
      "setObsFilter",
      "setSortColumn",
      "setSortDesc",
      "setPage",
      "setPerPage",
    ]),
    filterObsSelect(item, queryText, itemText) {
      const words = queryText.split(" ");
      const hasWords = itemText.split(" ");

      return words.some((word) => {
        return hasWords.some((hasWord) => {
          return hasWord.toLowerCase().indexOf(word.toLowerCase()) > -1;
        });
      });
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getListaobs();
    },
    getClasificacion(id) {
      const clasificacion = this.listado_categorias_obs.find(
        (item) => item.id === id
      );
      return clasificacion?.nombre;
    },
    getColor(active) {
      if (active) return "green";
      else return "grey";
    },
    paginate(params) {
      this.filters.page = params.pagina;
      this.filters.per_page = params.cantidad_por_pagina;
      this.getListaobs();
    },

    Agregarobs() {
      this.dialog = true;
    },
    cargarOBS() {
      this.dialogCargar = true;
    },
    sortByColumn({ sortBy, sortDesc }) {
      if (sortBy.length) {
        this.setSortColumn(sortBy[0]);
        this.setSortDesc(!sortDesc[0] ? "ASC" : "DESC");
        this.getListaobs();
      } else {
        this.setSortColumn(null);
        this.setSortDesc(null);
      }
    },
    verProveedor(item) {
      this.nuevoObs = Object.assign({}, item);
      this.getObsPadreList();
      this.selectedParent = item.id_obs_padre;
      this.nuevoObs.id_obs_padre = item.id_obs_padre;
      this.dialog = true;
      this.isEditable = true;
    },

    clearNuevoOBS() {
      Object.keys(this.nuevoObs).forEach((key) => {
        this.nuevoObs[key] = null;
        if (key == "activo") this.nuevoObs[key] = true;
      });
      this.codigo = null;
      this.selectedParent = {};
      this.disableClassification = false;
      this.$v.nuevoObs.$reset();
      this.isEditable = false;
    },
    generadorCodigo(obs) {
      this.listado_obs_padre.forEach((element) => {
        if (obs === element.id) {
          const nuevocod = Number(element.codigo) + 1;
          const code = nuevocod.toString();
          this.nuevoObs.codigo = code;
        }
      });
    },
    setParentOBS() {
      this.nuevoObs.id_obs_padre = this.selectedParent.id;
      if (this.selectedParent.id_tipo_obs !== 1) {
        this.nuevoObs.id_obs_clasificacion =
          this.selectedParent.id_obs_clasificacion;
        this.disableClassification = true;
      } else {
        this.disableClassification = false;
      }
    },

    async listadotipoobs() {
      const response = await this.services.ObsType.getObstypelist();
      this.listado_obstype = response.data;
    },

    async GuardaNuevoObs() {
      this.$v.nuevoObs.$touch();
      if (!this.$v.nuevoObs.$invalid) {
        let response = null;

        if (this.isEditable) {
          if (this.nuevoObs.id_tipo_obs == 1)
            delete this.nuevoObs["id_obs_padre"];

          response = await this.putActualizarObs(this.nuevoObs);
        } else {
          response = await this.PostAgregarObs(this.nuevoObs);
        }

        if (response.status === 201 || response.status === 200) {
          this.temporalAlert({
            show: true,
            message: `OBS ${
              this.isEditable ? "actualizado" : "guardado"
            } exitosamente`,
            type: "success",
          });
          this.getListaobs();
          this.getListaObsSelect();
          this.clearNuevoOBS();
          this.disableClassification = false;
          this.dialog = false;
        }
      }
    },
    async saveDocument() {
      this.$v.documento.$touch();
      if (!this.$v.documento.$invalid) {
        const data = new FormData();
        data.append("file", this.documento);
        const response = await this.services.Obs.postObsDocument(data);
        if (response.status === 200) {
          this.documento = null;
          this.$v.documento.$reset();
          this.getListaobs();
          this.getListaObsSelect();
          this.dialogCargar = false;
        }
      }
    },
    async searchObsSelect(value) {
      const regex_alphaNum = /^[a-zA-ZÀ-ÿ0-9- ]*$/;
      if (!regex_alphaNum.test(value)) {
        this.searchValidator = await value.replace(value, "");
      } else {
        if (this.obs_timer) {
          clearTimeout(this.obs_timer);
        }

        if (this.searchParam > 0) {
          return;
        }

        this.obs_timer = setTimeout(async () => {
          let params = {
            search: value,
            inactivos: true,
            mostrar_todos: true,
          };

          let codigoObs = Number(value);

          if (
            (codigoObs && codigoObs != NaN && value?.length >= 2) ||
            (value?.length > 2 && isNaN(codigoObs))
          ) {
            const { data } = await this.services.Obs.getObsList(params);
            const elements = [];
            data.forEach((obs) => {
              elements.push({
                id: obs.id,
                codigo: obs.codigo,
                nombre: obs.nombre,
                codigoNombre: `${obs.codigo} - ${obs.nombre}`,
              });
            });
            this.listado_obs_select_new = elements;
          }
          this.isLoading = false;
        }, 500);
      }
    },
    setFilter() {
      this.setPage(1);
      this.setObsFilter(this.searchParam);
      this.getListaobs();
    },
    async getObsPadreList() {
      this.nuevoObs.id_obs_padre = null;
      const response = await this.services.Obs.getObsList({
        pagination: false,
        id_tipo_obs: this.nuevoObs.id_tipo_obs,
      });

      if (response?.status == 200) {
        this.listado_obs_padre = response?.data.map((col) => ({
          id: col.id,
          nombre: `${col.codigo} - ${col.nombre}`,
          codigo: col.codigo,
          id_obs_clasificacion: col.id_obs_clasificacion,
        }));
      }
    },
    async downloadFormat() {
      const response = await this.services.Obs.getObsExcelDocument();
      // Descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "formato_catalogo_obs.xlsx");
      document.body.appendChild(link);
      link.click();
    },
  },
  async created() {
    this.listadotipoobs();
    this.getListaobs();
    // this.getListaObsSelect();
    this.getListadoCategoriasObs();
  },
  beforeDestroy() {
    this.filters.page = 1;
    this.filters.per_page = 10;
  },
};
</script>

<style lang="scss" scoped>
:deep(.v-chip) {
  width: -webkit-fill-available !important;
  display: flex !important;
  justify-content: center !important;
}

:deep(.theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th.sortable) {
  text-align: center !important;
}
</style>
